import {Blocks} from 'blocks/blocks'
import {DPProductNode} from 'types/DPTypes'
import {Paragraph} from 'blocks/paragraph'
import React from 'react'

export const Product: React.FC<DPProductNode> = (node) => {
	return (
		<Blocks blocks={node.blocks}>
			{node.blocks.map((block) => (
				<Paragraph {...block} key={block.id} />
			))}
		</Blocks>
	)
}
