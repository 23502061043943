import {Blocks} from 'blocks/blocks'
import {DPCaseNode} from 'types/DPTypes'
import {Hero} from 'blocks/hero/hero'
import {Paragraph} from 'blocks/paragraph'
import React from 'react'

export const Case: React.FC<DPCaseNode> = (node) => {
	const {title, background_image, description} = node

	return (
		<Blocks blocks={node.blocks}>
			<Hero
				title={title}
				background_image={background_image}
				description={description}
			/>
			{node.blocks.map((block) => (
				<Paragraph {...block} key={block.id} />
			))}
		</Blocks>
	)
}
