import {DPArticle, DPDossierNode, DPPress, DPVideoPage} from 'types/DPTypes'
import {Magazine, MagazineItems} from 'blocks/readingroom/components/magazine'

import {Blocks} from 'blocks/blocks'
import {Hero} from 'blocks/hero/hero'
import {Paragraph} from 'blocks/paragraph'
import React from 'react'
import css from './dossier.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Dossier: React.FC<DPDossierNode> = (node) => {
	const t = useTranslation()
	const {title, background_image, description, articles, blocks} = node

	return (
		<Blocks blocks={blocks || []}>
			<Hero
				background_image={background_image}
				subtitle={t.dossier}
				title={title}
				description={description}
				mod="small"
			/>
			{blocks && blocks.length > 0 && (
				<div className={styles.dossier.blocks()}>
					{blocks?.map((block) => {
						if (block._type === 'inpagenav')
							return <Paragraph {...block} display_dark={true} key={block.id} />
						return <Paragraph {...block} key={block.id} />
					})}
				</div>
			)}
			<DossierMagazine articles={articles} />
		</Blocks>
	)
}

const DossierMagazine: React.FC<{
	articles: (DPArticle | DPPress | DPVideoPage)[]
}> = ({articles}) => {
	if (!articles || articles.length === 0) return null

	return (
		<Magazine>
			<MagazineItems items={articles} />
		</Magazine>
	)
}
