import {
	Contentsidebar,
	ContentsidebarContent,
	ContentsidebarSidebar
} from 'layout/sidebar/contentsidebar'
import React, {useEffect, useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {Blocks} from 'blocks/blocks'
import {DPPressNode} from 'types/DPTypes'
import {Hero} from 'blocks/hero/hero'
import {Paragraph} from 'blocks/paragraph'
import {Sidebar} from 'layout/sidebar/sidebar'
import {Theme} from 'layout/theme'
import {countWords} from 'util/countWords'
import css from './press.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Press: React.FC<DPPressNode> = (node) => {
	// read more disabled
	const [visible, setVisible] = useState(true)
	const t = useTranslation()

	useEffect(() => {
		// @ts-ignore
		if (window['dataLayer']) {
			// @ts-ignore
			dataLayer.push({
				Author: (node?.contactpersons || []).map((item) => item.name),
				ContentType: 'persbericht',
				WordCount: countWords(node.content),
				ContentTags: node.tags.map((tag) => tag.name),
				PublishDate: node.date
			})
		}
	}, [])

	return (
		<div className={styles.press()}>
			<Hero
				background_image={node.background_image}
				subtitle={
					node.partner_related ? t.press.partner_related : t.press.title
				}
				title={node.title}
				description={node.description}
			/>
			<Contentsidebar className={styles.press.contentsidebar()}>
				<ContentsidebarContent>
					<div className={styles.press.contentsidebar.content.is({visible})()}>
						<AnimateHeight
							height={visible ? 'auto' : 450}
							duration={500}
							animateOpacity={true}
						>
							<Blocks fullwidth={true} blocks={node.content}>
								{node.content.map((block) => (
									<Paragraph {...block} key={block.id} />
								))}
							</Blocks>
						</AnimateHeight>
					</div>
					{!visible && (
						<div className={styles.press.contentsidebar.cta()}>
							<Theme.Button
								onClick={() => setVisible(true)}
								aria-label={t.read_more_arialabel + node?.title}
								mod="rounded"
							>
								<Theme.ButtonText>{t.press.readmore}</Theme.ButtonText>
								<Theme.ButtonIcon />
							</Theme.Button>
						</div>
					)}
				</ContentsidebarContent>
				<ContentsidebarSidebar>
					<Sidebar
						contactpersons={node.contactpersons}
						tags={node.tags}
						date={node.date}
						more={node.more}
					/>
				</ContentsidebarSidebar>
			</Contentsidebar>
			<Blocks blocks={node.blocks}>
				{node.blocks.map((block) => (
					<Paragraph {...block} key={block.id} />
				))}
			</Blocks>
		</div>
	)
}
