import {Blocks} from 'blocks/blocks'
import {DPVideoPage} from 'types/DPTypes'
import {Hero} from 'blocks/hero/hero'
import React from 'react'
import {VideoBlock} from 'blocks/videoblock/videoblock'
import {dpDateToJsDate} from 'util/dates'
import {format} from 'date-fns'

export const Videopage: React.FC<DPVideoPage> = (node) => {
	const {title, description, embed_code, date} = node

	return (
		<Blocks blocks={[]}>
			<Hero
				title={title}
				description={description}
				subtitle={date ? format(dpDateToJsDate(date), 'd MMMM yyyy') : null}
			/>
			<VideoBlock
				_type="videoblock"
				embed_code={embed_code}
				id={null}
				size={null}
				overview={true}
			/>
		</Blocks>
	)
}
