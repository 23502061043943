import {DPJob, DPJobNode} from 'types/DPTypes'
import {Form, Formik} from 'formik'
import {FormField, FormRow} from 'layout/form/form'
import {
	HeroBreadcrumbs,
	HeroButton,
	HeroDescription,
	HeroSubtitle,
	HeroTitle
} from 'blocks/hero/hero'
import React, {Fragment, useEffect, useState} from 'react'

import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {Blocks} from 'blocks/blocks'
import {Icon} from 'assets/icon'
import {JOB_MAIL_URL} from 'util/urls'
import {Overview} from 'blocks/overview/overview'
import {Relatedjobs} from 'blocks/relatedjobs/relatedjobs'
import {Share} from 'blocks/share/share'
import {Textblock} from 'blocks/textblock/textblock'
import {Theme} from 'layout/theme'
import {VideoBlock} from 'blocks/videoblock/videoblock'
import css from './job.module.scss'
import {fromModule} from 'util/styler/Styler'
import {getRelativeDate} from 'util/getRelativeDate'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

type JobFormData = {
	firstname?: string
	lastname?: string
	email?: string
}

export const Job: React.FC<DPJobNode> = (node) => {
	let defaultExpDate = new Date()
	defaultExpDate.setDate(defaultExpDate.getDate() + 30)
	const defaultExpDateString = defaultExpDate.toISOString().slice(0, 10)
	const jobData = {
		...node,
		expiration_date: node.expiration_date || defaultExpDateString
	}

	// Algolia routing issue workaround
	useEffect(() => {
		const handleRouteChange = () => {
			if (
				window.location.href.includes('/en/jobs') ||
				window.location.href.includes('/en/work-at-imec/job-opportunities') ||
				window.location.href.includes('/nl/je-carriere-bij-imec/vacatures')
			) {
				window.location.reload()
			}
		}
		window.addEventListener('popstate', handleRouteChange)
		return () => window.removeEventListener('popstate', handleRouteChange)
	}, [])

	return (
		<div className={styles.job()}>
			<JobMeta job={jobData} />
			<Blocks blocks={[]}>
				<JobHero {...jobData} />
				<div className={styles.job.content()}>
					<Textblock
						_type="textblock"
						id={0}
						display_grey={false}
						display_dark={false}
						text={jobData.body}
					/>
					<JobApply link={jobData.applylink} />
					<Overview
						_type="overview"
						id={null}
						columns={2}
						landscape={true}
						textual_display={false}
						title={null}
						white_background={false}
						items={[
							{
								_type: 'overview_video',
								id: null,
								embed_code: `<iframe src="https://player.vimeo.com/video/791892356?h=9f01e15824" width="640" height="268" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
								text: '<h5>Who we are</h5>',
								text_above: true
							},
							{
								_type: 'overview_video',
								id: null,
								embed_code: `<iframe allow="autoplay; fullscreen" allowfullscreen="" frameborder="0" height="640" width="360" src="https://player.vimeo.com/video/80567552"></iframe>`,
								text: "<h5>imec's cleanroom</h5>",
								text_above: true
							}
						]}
					/>

					<Relatedjobs
						jobs={node.related.jobs}
						_type="relatedjobs"
						id={node.node_id}
						text_left=""
						title_right={node.related.title}
						jobdetail={true}
					/>
					<JobForm job={node} />

					<Share />
				</div>
			</Blocks>
		</div>
	)
}

const JobApply: React.FC<{
	link: string
}> = ({link}) => {
	const t = useTranslation()
	if (!link) return null

	return (
		<Block type={null} className={styles.apply()}>
			<Theme.Container>
				<Theme.Button href={link} mod="rounded">
					<Theme.ButtonText>{t.job.apply}</Theme.ButtonText>
					<Theme.ButtonIcon />
				</Theme.Button>
			</Theme.Container>
		</Block>
	)
}

const BrandingVideo: React.FC = () => {
	const embedCode = `<iframe src="https://player.vimeo.com/video/241826838" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
	return (
		<VideoBlock
			embed_code={embedCode}
			_type="videoblock"
			id={null}
			size={null}
		/>
	)
}

const JobTestimonials: React.FC<{
	testimonials: string[]
}> = ({testimonials}) => {
	if (!testimonials) return null
	if (!testimonials.length) return null

	return (
		<Fragment>
			{testimonials.map((testimonial, id) => {
				if (!testimonial) return null
				return (
					<VideoBlock
						key={id}
						embed_code={testimonial}
						_type="videoblock"
						id={null}
						size={null}
					/>
				)
			})}
		</Fragment>
	)
}

const JobForm: React.FC<{job: DPJob}> = ({job}) => {
	const t = useTranslation()
	const [sending, setSending] = useState(false)
	const [sent, setSent] = useState(false)
	const location = job.region && job.region[0]?.name
	const category = job.type && job.type[0]?.name
	const tagline = (
		(category ? category : '') + (location ? location : '')
	).trim()

	return (
		<div className={styles.form()}>
			<Formik<JobFormData>
				initialValues={{
					firstname: null,
					lastname: null,
					email: null
				}}
				onSubmit={(values, formik) => {
					if (sending || sent) return
					setSending(true)
					fetch(JOB_MAIL_URL(), {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							...values,
							url: job.url,
							title: job.title,
							description: job.short_description,
							tagline: tagline
						})
					}).then((response) => {
						setSending(false)
						if (response.ok) {
							formik.resetForm()
							setSent(true)
						} else {
							response.json().then((errors) => formik.setErrors(errors))
						}
					})
				}}
			>
				<Form>
					<Theme.Container>
						<h3 className={styles.form.title()}>{t.job.form.title}</h3>
						{sent ? (
							<div className={styles.form.thanks()}>{t.job.form.thanks}</div>
						) : (
							<div className={styles.form.form()}>
								<div className={styles.form.fields()}>
									<FormRow>
										<FormField
											name="firstname"
											required
											label={t.form.firstname}
											mod={{dark: true, 50: true}}
										/>
										<FormField
											name="lastname"
											required
											label={t.form.lastname}
											mod={{dark: true, 50: true}}
										/>
									</FormRow>
									<FormField
										name="email"
										type="email"
										required
										label={t.form.email}
										mod={{dark: true}}
									/>
								</div>
								<div className={styles.form.submit.container()}>
									<button type="submit" className={styles.form.submit()}>
										<p className={styles.form.submit.text()}>{t.form.submit}</p>
										<div className={styles.form.submit.circle()}>
											<span className={styles.form.submit.circle.icon()}>
												<Icon icon="ArrowRight" />
											</span>
										</div>
									</button>
								</div>
							</div>
						)}
					</Theme.Container>
				</Form>
			</Formik>
		</div>
	)
}

function getJobtitleString(category: string, location: string, date: Date) {
	const relativeDate = getRelativeDate(date)

	if (!category && !location) return relativeDate
	if (!category || !location) return `${category || location} | ${relativeDate}`
	return `${category} - ${location} | ${relativeDate}`
}

const JobHero: React.FC<DPJobNode> = (node) => {
	const {header_image, title, short_description, applylink} = node
	const t = useTranslation()

	const jsDate = new Date(Date.parse(node.date.replace(/-/g, '/')))
	const location = node.region && node.region[0]?.name
	const category = node.type && node.type[0]?.name
	const subtitle = getJobtitleString(category, location, jsDate)

	return (
		<div
			className={styles.hero.mod({
				image: header_image
			})()}
		>
			{header_image && <BgFixed image={header_image} />}
			<HeroBreadcrumbs pageTitle={title} />
			<div className={styles.job.content()}>
				<Theme.Container>
					<HeroTitle>{title}</HeroTitle>
					{subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
					{short_description && (
						<HeroDescription>{short_description}</HeroDescription>
					)}
					{applylink && <HeroButton url={applylink}>{t.job.apply}</HeroButton>}
				</Theme.Container>
			</div>
		</div>
	)
}

const JobMeta: React.FC<{job: DPJobNode}> = ({job}) => {
	if (!job.address_details?.length) return null

	const postingMeta: Record<string, any> = {
		'@context': 'https://schema.org/',
		'@type': 'JobPosting',
		title: [job.title, ...(job.job_title || []).map((tax) => tax.name)],
		datePosted: job.date,
		validThrough: job.expiration_date,
		description: job.body,
		hiringOrganization: {
			'@type': 'Organization',
			name: 'imec',
			sameAs: process.env.NEXT_PUBLIC_WEBSITE_URL,
			logo: 'https://www.imec-int.com/imec-logo.png'
		},
		jobLocation: (job.address_details || []).map((details) => ({
			'@type': 'Place',
			address: {
				'@type': 'PostalAddress',
				streetAddress: details.address,
				postalCode: details.postal,
				addressLocality: details.city,
				addressRegion: details.state,
				addressCountry: details.country?.name
			}
		})),
		employmentType: [
			job.is_part_time ? 'PART_TIME' : 'FULL_TIME',
			...(job.employment_type || []).map((tax) => {
				switch (tax.name.toLowerCase()) {
					case 'full-time':
						return 'FULL_TIME'
					case 'part-time':
						return 'PART_TIME'
					case 'contractor':
						return 'CONTRACTOR'
					case 'master projects/internships':
					case 'bachelor projects':
					case 'bachelor projects/internships':
						return 'INTERN'
					default:
						return 'TEMPORARY'
				}
			})
		],
		url: process.env.NEXT_PUBLIC_WEBSITE_URL + job.url
	}
	if (job.remote_region) {
		postingMeta.applicantLocationRequirements = {
			'@type': 'Country',
			name: job.remote_region
		}
		postingMeta.jobLocationType = 'TELECOMMUTE'
	}

	return (
		<script type="application/ld+json">{JSON.stringify(postingMeta)}</script>
	)
}
