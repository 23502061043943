import React, {useEffect} from 'react'

import {Blocks} from 'blocks/blocks'
import {DPResearchProjectNode} from 'types/DPTypes'
import {Paragraph} from 'blocks/paragraph'
import {countWords} from 'util/countWords'

export const Researchproject: React.FC<DPResearchProjectNode> = (node) => {
	useEffect(() => {
		// @ts-ignore
		if (window['dataLayer']) {
			// @ts-ignore
			dataLayer.push({
				ContentType: '(icon)',
				WordCount: countWords(node.blocks),
				ContentTags: node.tags.map((tag) => tag.name),
				PublishDate: node.date
			})
		}
	}, [])

	return (
		<Blocks blocks={node.blocks}>
			{node.blocks.map((block) => (
				<Paragraph {...block} key={block.id} />
			))}
		</Blocks>
	)
}
