import {Blocks} from 'blocks/blocks'
import {DPPageNode} from 'types/DPTypes'
import {Paragraph} from 'blocks/paragraph'
import React from 'react'

export const Page: React.FC<DPPageNode> = (node) => {
	return (
		<Blocks blocks={node.blocks}>
			{node.blocks.map((block, index) => (
				<Paragraph
					{...block}
					firstBlock={Boolean(index === 0)}
					key={block.id}
				/>
			))}
		</Blocks>
	)
}
