import {DPPodcastEpisodeBlock, DPPodcastNode} from 'types/DPTypes'

import {Blocks} from 'blocks/blocks'
import {CookieMissing} from 'layout/cookies/cookiemissing'
import {Hero} from 'blocks/hero/hero'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './podcast.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Podcast: React.FC<DPPodcastNode> = (node) => {
	const {title, background_image, description, platforms, episodes} = node

	return (
		<Blocks blocks={[]}>
			<Hero
				title={title}
				background_image={background_image}
				description={description}
				subtitle={'Podcast'}
			/>
			<div className={styles.podcast()}>
				<Theme.Container>
					{platforms?.length && (
						<p className={styles.podcast.platforms()}>
							<span>{'Listen on: '}</span>
							{platforms.map((p, i) => (
								<span key={i}>
									{' '}
									<a href={p.url} target={'_blank'}>
										{p.title}
									</a>
									{i + 1 < platforms.length && ' |'}
								</span>
							))}
						</p>
					)}
					<div>
						{episodes?.map((episodeBlock, i) => (
							<Episode key={`episode${i}`} {...episodeBlock} />
						))}
					</div>
				</Theme.Container>
			</div>
		</Blocks>
	)
}

const Episode: React.FC<DPPodcastEpisodeBlock> = (block) => {
	return (
		<div className={styles.episode()}>
			<CookieMissing object={block.embed_code}>
				<div dangerouslySetInnerHTML={{__html: block.embed_code}} />
			</CookieMissing>
			<Theme.Wysiwyg className={styles.episode.description()}>
				{block.desc}
			</Theme.Wysiwyg>
		</div>
	)
}
